// 🛑 NOTICE: __generated__ folders should be added to .gitignore
// 🛑 In this repo I keep generated files only for demo purposes!
import * as Types from '../../../__generated__/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type StopSessionWAMutationVariables = Types.Exact<{
  host: Types.Scalars['String']['input'];
}>;


export type StopSessionWAMutation = { __typename: 'Mutation', stopSessionWA?: boolean | null };


export const StopSessionWAMutationDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"StopSessionWAMutation"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"host"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"stopSessionWA"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"host"},"value":{"kind":"Variable","name":{"kind":"Name","value":"host"}}}]}]}}]} as unknown as DocumentNode;
export type StopSessionWAMutationMutationFn = Apollo.MutationFunction<StopSessionWAMutation, StopSessionWAMutationVariables>;

/**
 * __useStopSessionWAMutation__
 *
 * To run a mutation, you first call `useStopSessionWAMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStopSessionWAMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [stopSessionWaMutation, { data, loading, error }] = useStopSessionWAMutation({
 *   variables: {
 *      host: // value for 'host'
 *   },
 * });
 */
export function useStopSessionWAMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<StopSessionWAMutation, StopSessionWAMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<StopSessionWAMutation, StopSessionWAMutationVariables>(StopSessionWAMutationDocument, options);
      }
export type StopSessionWAMutationHookResult = ReturnType<typeof useStopSessionWAMutation>;
export type StopSessionWAMutationMutationResult = Apollo.MutationResult<StopSessionWAMutation>;
export type StopSessionWAMutationMutationOptions = Apollo.BaseMutationOptions<StopSessionWAMutation, StopSessionWAMutationVariables>;