import { motion } from 'framer-motion';
import { useParams } from 'react-router-dom';
import Loading from 'shared/images/loading.svg';
import { formatPhoneNumber } from 'utils/formatPhoneNumber';
import Header from 'widgets/header';
import {
  ConnectionSessionWAQueryDocument,
  useConnectionSessionWAQuery,
} from './__generated__/ConnectionSessionWAQuery';
import { useSubscriptionSessionWA } from './__generated__/SubscriptionSessionWA';
import { AccountStatus } from './components/accountStatus';
import { QrCode } from './components/qrCode';
import { Helmet } from 'react-helmet-async';

const MainPage: React.FC = () => {
  const host = useParams().host || '';
  const { data, loading, error } = useConnectionSessionWAQuery({
    variables: { host },
    pollInterval: 5000,
  });
  const status = data?.connectionSessionWA?.statusWbot;
  let statusIcon;
  if (status === 'closed') {
    statusIcon = '🔴 ';
  } else if (status === 'init') {
    statusIcon = '🟡 ';
  } else if (status === 'loading') {
    statusIcon = '🔃 ';
  } else if (status === 'qr') {
    statusIcon = '🔔 ';
  } else if (status === 'connected') {
    statusIcon = '🟢 ';
  }

  useSubscriptionSessionWA({
    variables: { host },
    onData: ({ client, data }) => {
      client.writeQuery({
        query: ConnectionSessionWAQueryDocument,
        variables: { host },
        data: {
          connectionSessionWA: data.data?.subscribeSessionWA,
        },
      });
    },
  });

  if (loading)
    return (
      <div className="flex flex-col grow-1 h-screen">
        <Helmet>
          <title>{host}</title>
        </Helmet>
        <div className="flex items-center justify-center h-full w-full rounded-[44px] bg-white my-20 shadow-[0px_10px_50px_rgba(0,0,0,0.1)]">
          <motion.img
            animate={{ rotate: 360 }}
            transition={{ duration: 1.4, repeat: Infinity, repeatType: 'loop', ease: 'linear' }}
            className="w-48"
            src={Loading}
            alt="loading"
          />
        </div>
      </div>
    );

  return (
    <>
      <Helmet>
        <title>{statusIcon + host}</title>
      </Helmet>
      <Header />
      <main className="flex flex-col w-full items-center gap-y-4 md:gap-y-8 mb-10">
        <motion.div
          initial={{ boxShadow: '0 2px 4px 0 #28282813' }}
          animate={{ boxShadow: '0 7px 20px 1px #00000014' }}
          transition={{ duration: 1, delay: 0.2 }}
          className="py-5 w-full md:w-[490px] text-center text-2xl bg-slate-100 font-semibold text-gray-700 rounded-3xl"
        >
          {data?.connectionSessionWA?.waClient?.phone === '0' && !error && (
            <p>Отсканируйте QR код</p>
          )}
          {data?.connectionSessionWA?.waClient?.phone !== '0' && !error && (
            <p>{formatPhoneNumber(data?.connectionSessionWA?.waClient?.phone)}</p>
          )}
          {error ? <p>Перезагружается</p> : null}
        </motion.div>
        <motion.div
          initial={{ boxShadow: '0 2px 4px 0 #28282813' }}
          animate={{ boxShadow: '0 7px 20px 1px #00000014' }}
          transition={{ duration: 1, delay: 0.2 }}
          className="flex flex-col bg-slate-100 rounded-3xl w-full md:w-fit md:mt-0 items-center"
        >
          <QrCode
            error={error}
            host={host}
            status={data?.connectionSessionWA?.statusWbot}
            qrcode={data?.connectionSessionWA?.waClient?.qrcode}
          />
        </motion.div>

        {!error ? (
          <motion.div
            initial={{ boxShadow: '0 2px 4px 0 #28282813' }}
            animate={{ boxShadow: '0 7px 20px 1px #00000014' }}
            transition={{ duration: 1, delay: 0.2 }}
            className="flex flex-col bg-slate-100 rounded-3xl w-full md:w-[500px] gap-y-4 py-6 px-4 items-center"
          >
            <div className="flex flex-col md:flex-row w-full items-start md:items-center md:gap-x-3">
              <div className="flex flex-row gap-x-3 items-center">
                <h2 className="font-bold text-xl select-none text-gray-700">Состояние:</h2>
              </div>
              <div className="flex flex-row gap-x-2 items-center w-full">
                <p className="text-lg text-gray-600 whitespace-pre-line w-full break-words leading-none">
                  {data?.connectionSessionWA?.notification}
                </p>
              </div>
            </div>
            {data?.connectionSessionWA?.statusWbot === 'qr' && (
              <div className="flex flex-col md:flex-row w-full items-start md:items-center md:gap-x-3">
                <h2 className="font-bold text-xl select-none text-gray-700">Попыток:</h2>
                <p className="text-lg rounded-xl text-gray-600">
                  {data?.connectionSessionWA?.waClient?.retries}
                </p>
              </div>
            )}
            <div className="flex flex-col md:flex-row w-full items-start md:items-center md:gap-x-3">
              <div className="flex flex-row gap-x-3 items-center">
                <h2 className="font-bold text-xl select-none text-gray-700">Хост:</h2>
              </div>
              <div className="flex flex-row gap-x-2 items-center w-full">
                <p className="text-lg text-gray-600 whitespace-pre-line w-full break-words leading-none">
                  {data?.connectionSessionWA?.waClient?.host}
                </p>
              </div>
            </div>
            <div className="flex flex-col md:flex-row w-full items-start md:items-center md:gap-x-3">
              <div className="flex flex-row gap-x-3 items-center">
                <h2 className="font-bold text-xl select-none text-gray-700">VPS:</h2>
              </div>
              <div className="flex flex-row gap-x-2 items-center w-full">
                <p className="text-lg text-gray-600 whitespace-pre-line w-full break-words leading-none">
                  {data?.connectionSessionWA?.nameVitrualMachine}
                </p>
              </div>
            </div>
            {data?.connectionSessionWA?.needPhone && (
              <div className="flex flex-col md:flex-row w-full items-start md:items-center md:gap-x-3">
                <h2 className="font-bold text-xl select-none text-gray-700">Нужный телефон:</h2>
                <p className="text-lg rounded-xl text-gray-600">
                  {data?.connectionSessionWA?.needPhone}
                </p>
              </div>
            )}
            <div className="flex flex-col md:flex-row w-full items-start md:items-center md:gap-x-3">
              <h2 className="font-bold text-xl select-none text-gray-700">Тип аккаунта:</h2>
              <p className="text-lg rounded-xl text-gray-600">
                {data?.connectionSessionWA?.waClient?.status}
              </p>
            </div>
            <div className="flex flex-col md:flex-row w-full items-start md:items-center md:gap-x-3">
              <h2 className="font-bold text-xl select-none text-gray-700">Статус:</h2>
              <AccountStatus status={data?.connectionSessionWA?.statusWbot} />
            </div>
          </motion.div>
        ) : null}
      </main>
    </>
  );
};

export default MainPage;
