export const Page404 = () => {
  return (
    <div className="w-full h-screen flex justify-center items-center relative">
      <h1 className="text-[6vw] leading-[6vw] md:text-[3vw] md:leading-[3vw] xl:text-[2vw] xl:leading-[2vw] text-center font-medium text-gray-700">
        Номер телефона не найден
      </h1>
    </div>
  );
};

export default Page404;
