import { ApolloProvider } from '@apollo/client';
import { client } from 'apollo/client';
import App from 'app/app';
import 'app/styles/index.scss';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

const container = document.querySelector('#root') as HTMLElement;
const root = createRoot(container);

root.render(
  <ApolloProvider client={client}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </ApolloProvider>
);
