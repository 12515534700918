// 🛑 NOTICE: __generated__ folders should be added to .gitignore
// 🛑 In this repo I keep generated files only for demo purposes!
import * as Types from '../../../__generated__/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type LogoutSessionWAMutationVariables = Types.Exact<{
  host: Types.Scalars['String']['input'];
}>;


export type LogoutSessionWAMutation = { __typename: 'Mutation', logoutSessionWA?: boolean | null };


export const LogoutSessionWAMutationDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"LogoutSessionWAMutation"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"host"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"logoutSessionWA"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"host"},"value":{"kind":"Variable","name":{"kind":"Name","value":"host"}}}]}]}}]} as unknown as DocumentNode;
export type LogoutSessionWAMutationMutationFn = Apollo.MutationFunction<LogoutSessionWAMutation, LogoutSessionWAMutationVariables>;

/**
 * __useLogoutSessionWAMutation__
 *
 * To run a mutation, you first call `useLogoutSessionWAMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutSessionWAMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutSessionWaMutation, { data, loading, error }] = useLogoutSessionWAMutation({
 *   variables: {
 *      host: // value for 'host'
 *   },
 * });
 */
export function useLogoutSessionWAMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LogoutSessionWAMutation, LogoutSessionWAMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<LogoutSessionWAMutation, LogoutSessionWAMutationVariables>(LogoutSessionWAMutationDocument, options);
      }
export type LogoutSessionWAMutationHookResult = ReturnType<typeof useLogoutSessionWAMutation>;
export type LogoutSessionWAMutationMutationResult = Apollo.MutationResult<LogoutSessionWAMutation>;
export type LogoutSessionWAMutationMutationOptions = Apollo.BaseMutationOptions<LogoutSessionWAMutation, LogoutSessionWAMutationVariables>;