import { Navigate, Route, Routes } from 'react-router-dom';
import Page404 from 'pages/404';
import Main from 'pages/mainPage';

export const AppRouter = () => {
  return (
    <Routes>
      <Route path="/:host" element={<Main />} />
      <Route path="/" element={<Navigate to="/404" />} />
      <Route path="404" element={<Page404 />} />
    </Routes>
  );
};
