import { ApolloError } from '@apollo/client';
import { message } from 'antd';
import { motion } from 'framer-motion';
import { useLogoutSessionWAMutation } from 'pages/mainPage/__generated__/LogoutSessionWAMutation';
import { useStartSessionWAMutation } from 'pages/mainPage/__generated__/StartSessionWAMutation';
import { useStopSessionWAMutation } from 'pages/mainPage/__generated__/StopSessionWAMutation';
import { useEffect, useState } from 'react';
import ReactLoading from 'react-loading';
import Cursor from 'shared/images/cursor.svg';

import QRCode from 'react-qr-code';
import Checkmark from 'shared/images/checkmark.svg';
import Restart from 'shared/images/restart.svg';

interface QrCodeProps {
  host: string;
  status: string | null | undefined;
  qrcode: string | null | undefined;
  error: ApolloError | undefined;
}

export const QrCode = (props: QrCodeProps) => {
  const { host, status, qrcode, error } = props;
  const [startSession] = useStartSessionWAMutation({
    variables: { host },
    onCompleted: () => {
      message.success('Сессия успешно запущена');
    },
    onError: () => {
      message.error('Произошла ошибка при запуске сессии');
    },
  });
  const [stopSession] = useStopSessionWAMutation({
    variables: { host },
    onCompleted: () => {
      message.success('Сессия успешно закрыта!');
    },
    onError: () => {
      message.error('Произошла ошибка при закрытии сессии');
    },
  });
  const [logoutSession] = useLogoutSessionWAMutation({
    variables: { host },
    onCompleted: () => {
      message.success('Сессия сброшена!');
    },
    onError: () => {
      message.error('Произошла ошибка при logout из сессии');
    },
  });
  const isAdmin = localStorage.getItem('isAdmin') === 'true';
  const [clickedButton, setClickedButton] = useState<boolean>();

  const isClosedStatus = status === 'closed';
  const isInitStatus = status === 'init';
  const isLoadingStatus = status === 'loading';
  const isQRStatus = status === 'qr';
  const isConnectedStatus = status === 'connected';
  useEffect(() => {
    setClickedButton(false);
  }, [status]);
  return (
    <>
      <div className="flex relative">
        <motion.div
          key={'qr'}
          initial={{ filter: isQRStatus ? 'blur(0px)' : 'blur(1px)', scale: 0.9, y: 10 }}
          animate={{ filter: isQRStatus ? 'blur(0px)' : 'blur(7px)', scale: 1, y: 0 }}
          transition={{ scale: { duration: 0.7 }, y: { duration: 0.3 } }}
          className="md:w-[490px] w-full md:h-[360px] h-[300px] flex items-center justify-center"
        >
          {!error ? (
            <div>
              <QRCode
                className="select-none hidden md:flex"
                bgColor="transparent"
                size={280}
                fgColor="#383838"
                value={qrcode || ''}
              />
              <QRCode
                className="select-none md:hidden flex"
                bgColor="transparent"
                size={230}
                fgColor="#383838"
                value={qrcode || ''}
              />
            </div>
          ) : null}
        </motion.div>
        {error ? (
          <motion.div
            key={'error'}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.8 }}
            className=" w-fit h-fit absolute left-0 right-0 top-0 bottom-0 m-auto"
          >
            <ReactLoading type={'spin'} color={'#0070b9'} height={'200px'} width={'200px'} />
          </motion.div>
        ) : null}
        {(clickedButton || isInitStatus || isLoadingStatus) && !error && (
          <motion.button
            key={'restart'}
            initial={{ scale: 1.2, y: 10 }}
            animate={{ scale: 1, y: 0 }}
            whileHover={{ rotate: !isInitStatus && !isLoadingStatus ? 12 : 0 }}
            transition={{ duration: 0.7, rotate: { duration: 0.1 } }}
            className="absolute left-6 top-16 md:left-28 md:top-14"
            disabled={isInitStatus}
          >
            <motion.img
              key={'restart-img'}
              animate={{ rotate: isInitStatus || isLoadingStatus ? 360 : 0 }}
              transition={{ duration: 8, repeat: Infinity, ease: 'linear', repeatType: 'loop' }}
              className="md:size-64 size-44 select-none"
              src={Restart}
              alt="Restart"
            />
          </motion.button>
        )}
        {!clickedButton && isClosedStatus && !error && (
          <motion.button
            key={'start-button'}
            initial={{ scale: 0.9, y: 10 }}
            animate={{ scale: 1, y: 0 }}
            transition={{ scale: { duration: 0.4 }, y: { duration: 0.2 } }}
            onClick={() => {
              if (!clickedButton) {
                setClickedButton(true);
                startSession();
              }
            }}
            className="absolute z-40 left-0 right-0 top-0 bottom-0 m-auto w-fit h-fit p-6 bg-[#9bed9e] hover:bg-[#90dc93] duration-50 group rounded-2xl"
          >
            <img src={Cursor} alt="Cursor" className="size-20 group-hover:scale-90 duration-200" />
            <p className="text-2xl text-white font-semibold duration-200">ЖМИ</p>
          </motion.button>
        )}
        {(clickedButton || isInitStatus || isLoadingStatus) && !error && (
          <div className="absolute z-50 left-0 right-0 top-0 bottom-0 m-auto bg-slate-100/50 rounded-2xl flex items-center justify-center backdrop-blur-sm">
            <p className="select-none text-2xl font-semibold">Подождите...</p>
          </div>
        )}
        {isConnectedStatus && !error && (
          <motion.img
            key={'checkmark'}
            initial={{ scale: 1.2, y: 10 }}
            animate={{ scale: 1, y: 0 }}
            transition={{ duration: 0.7 }}
            className="absolute left-6 top-16 md:left-28 md:top-14 md:size-64 size-44 select-none"
            src={Checkmark}
            alt="Checkmark"
          />
        )}
      </div>

      {isAdmin && !error && (
        <div className="flex flex-col gap-y-3 mb-6 sm:grid sm:grid-flow-col gap-x-4">
          <motion.button
            key={'close-button'}
            whileTap={{ scale: 0.9 }}
            transition={{ duration: 0.1 }}
            className="bg-gray-300 text-white rounded-3xl px-4 py-2 font-semibold hover:bg-gray-300/80 transition-colors"
            onClick={() => {
              stopSession();
            }}
          >
            Закрыть сессию
          </motion.button>
          <motion.button
            key={'hard-restart-button'}
            whileTap={{ scale: 0.9 }}
            transition={{ duration: 0.1 }}
            className="bg-[#0070b9] text-white rounded-3xl px-4 py-2 font-semibold hover:bg-[#0070b9]/80 transition-colors"
            onClick={() => {
              logoutSession();
            }}
          >
            Жесткая перезагрузка
          </motion.button>
        </div>
      )}
    </>
  );
};
