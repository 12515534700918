import { HelmetProvider } from 'react-helmet-async';
import AppRouter from './providers/router';

const App = () => {
  return (
    <HelmetProvider>
      <AppRouter />
    </HelmetProvider>
  );
};
export default App;
