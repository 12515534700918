// 🛑 NOTICE: __generated__ folders should be added to .gitignore
// 🛑 In this repo I keep generated files only for demo purposes!
import * as Types from '../../../__generated__/types';

import { DocumentNode } from 'graphql';
import { ConnectionSessionWA } from './ConnectionSessionWA.fragment';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type ConnectionSessionWAQueryVariables = Types.Exact<{
  host: Types.Scalars['String']['input'];
}>;


export type ConnectionSessionWAQuery = { __typename: 'Query', connectionSessionWA?: { __typename: 'ConnectionSessionWA', statusWbot?: string | null, percent?: number | null, notification?: string | null, needPhone?: string | null, nameVitrualMachine?: string | null, waClient?: { __typename: 'Wbot', history?: Array<string | null> | null, novoreg_date?: string | null, progrev_date?: string | null, progrev_good?: boolean | null, progrev_number?: number | null, qrcode?: string | null, host?: string | null, phone?: string | null, retries?: string | null, status?: string | null, wmmail_good?: boolean | null, wmmail_number?: number | null } | null } | null };


export const ConnectionSessionWAQueryDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"ConnectionSessionWAQuery"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"host"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"connectionSessionWA"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"host"},"value":{"kind":"Variable","name":{"kind":"Name","value":"host"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ConnectionSessionWA"}}]}}]}},...ConnectionSessionWA.definitions]} as unknown as DocumentNode;

/**
 * __useConnectionSessionWAQuery__
 *
 * To run a query within a React component, call `useConnectionSessionWAQuery` and pass it any options that fit your needs.
 * When your component renders, `useConnectionSessionWAQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConnectionSessionWAQuery({
 *   variables: {
 *      host: // value for 'host'
 *   },
 * });
 */
export function useConnectionSessionWAQuery(baseOptions: ApolloReactHooks.QueryHookOptions<ConnectionSessionWAQuery, ConnectionSessionWAQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<ConnectionSessionWAQuery, ConnectionSessionWAQueryVariables>(ConnectionSessionWAQueryDocument, options);
      }
export function useConnectionSessionWAQueryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ConnectionSessionWAQuery, ConnectionSessionWAQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<ConnectionSessionWAQuery, ConnectionSessionWAQueryVariables>(ConnectionSessionWAQueryDocument, options);
        }
export type ConnectionSessionWAQueryHookResult = ReturnType<typeof useConnectionSessionWAQuery>;
export type ConnectionSessionWAQueryLazyQueryHookResult = ReturnType<typeof useConnectionSessionWAQueryLazyQuery>;
export type ConnectionSessionWAQueryQueryResult = Apollo.QueryResult<ConnectionSessionWAQuery, ConnectionSessionWAQueryVariables>;