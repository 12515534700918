import { motion } from 'framer-motion';
import Disabled from 'shared/images/disabled.svg';
import QRCode from 'shared/images/qrIcon.svg';
import Loading from 'shared/images/rocketIcon.svg';
import Online from 'shared/images/www.svg';

interface AccountStatusProps {
  status: string | null | undefined;
}

export const AccountStatus = (props: AccountStatusProps) => {
  const { status } = props;
  if (status === 'closed') {
    return (
      <div key="closed" className="flex flex-row gap-x-2 items-center">
        <motion.img
          key="closed-img"
          animate={{ rotate: 360 }}
          transition={{ duration: 8, repeat: Infinity, ease: 'linear', repeatType: 'loop' }}
          className="size-6 select-none"
          src={Disabled}
          alt="Offline"
        />
        <p className="text-lg text-gray-600 text-nowrap">Сессия закрыта</p>
      </div>
    );
  }
  if (status === 'init') {
    return (
      <div key="init" className="flex flex-row gap-x-2 items-center">
        <motion.img
          key="init-img"
          initial={{ x: 0 }}
          animate={{ x: 4 }}
          transition={{ duration: 1, repeat: Infinity, repeatType: 'mirror' }}
          className="size-6 select-none"
          src={Loading}
          alt="Loading"
        />
        <p className="text-lg text-gray-600 text-nowrap">Запуск</p>
      </div>
    );
  }
  if (status === 'loading') {
    return (
      <div key="init" className="flex flex-row gap-x-2 items-center">
        <motion.img
          key="init-img"
          initial={{ x: 0 }}
          animate={{ x: 4 }}
          transition={{ duration: 1, repeat: Infinity, repeatType: 'mirror' }}
          className="size-6 select-none"
          src={Loading}
          alt="Loading"
        />
        <p className="text-lg text-gray-600 text-nowrap">Запуск</p>
      </div>
    );
  }
  if (status === 'qr') {
    return (
      <div key="qr" className="flex flex-row gap-x-2 items-center">
        <motion.img
          key="qr-img"
          initial={{ scale: 1 }}
          animate={{ scale: 1.1 }}
          transition={{ duration: 0.7, repeat: Infinity, repeatType: 'mirror' }}
          className="size-6 select-none"
          src={QRCode}
          alt="QRCode"
        />
        <p className="text-lg text-gray-600 text-nowrap">Отдаёт QR</p>
      </div>
    );
  }
  if (status === 'connected') {
    return (
      <div className="flex flex-row gap-x-2 items-center">
        <img className="size-6 select-none" src={Online} alt="Loading" />
        <p className="text-lg text-gray-600 text-nowrap">Авторизован</p>
      </div>
    );
  }
  return null;
};
