// 🛑 NOTICE: __generated__ folders should be added to .gitignore
// 🛑 In this repo I keep generated files only for demo purposes!
import * as Types from '../../../__generated__/types';

import { DocumentNode } from 'graphql';
export type ConnectionSessionWA = { __typename: 'ConnectionSessionWA', statusWbot?: string | null, percent?: number | null, notification?: string | null, needPhone?: string | null, nameVitrualMachine?: string | null, waClient?: { __typename: 'Wbot', history?: Array<string | null> | null, novoreg_date?: string | null, progrev_date?: string | null, progrev_good?: boolean | null, progrev_number?: number | null, qrcode?: string | null, host?: string | null, phone?: string | null, retries?: string | null, status?: string | null, wmmail_good?: boolean | null, wmmail_number?: number | null } | null };

export type Wbot = { __typename: 'Wbot', history?: Array<string | null> | null, novoreg_date?: string | null, progrev_date?: string | null, progrev_good?: boolean | null, progrev_number?: number | null, qrcode?: string | null, host?: string | null, phone?: string | null, retries?: string | null, status?: string | null, wmmail_good?: boolean | null, wmmail_number?: number | null };

export const Wbot = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"Wbot"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Wbot"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"history"}},{"kind":"Field","name":{"kind":"Name","value":"novoreg_date"}},{"kind":"Field","name":{"kind":"Name","value":"progrev_date"}},{"kind":"Field","name":{"kind":"Name","value":"progrev_good"}},{"kind":"Field","name":{"kind":"Name","value":"progrev_number"}},{"kind":"Field","name":{"kind":"Name","value":"qrcode"}},{"kind":"Field","name":{"kind":"Name","value":"host"}},{"kind":"Field","name":{"kind":"Name","value":"phone"}},{"kind":"Field","name":{"kind":"Name","value":"retries"}},{"kind":"Field","name":{"kind":"Name","value":"status"}},{"kind":"Field","name":{"kind":"Name","value":"wmmail_good"}},{"kind":"Field","name":{"kind":"Name","value":"wmmail_number"}}]}}]} as unknown as DocumentNode;
export const ConnectionSessionWA = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"ConnectionSessionWA"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"ConnectionSessionWA"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"waClient"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"Wbot"}}]}},{"kind":"Field","name":{"kind":"Name","value":"statusWbot"}},{"kind":"Field","name":{"kind":"Name","value":"percent"}},{"kind":"Field","name":{"kind":"Name","value":"notification"}},{"kind":"Field","name":{"kind":"Name","value":"needPhone"}},{"kind":"Field","name":{"kind":"Name","value":"nameVitrualMachine"}}]}},...Wbot.definitions]} as unknown as DocumentNode;